import React from "react";
import "./styles/about.css";
import iconpeace from "./styles/icon-peace.png";
import { FaReact } from "react-icons/fa";
import { SiRubyonrails } from "react-icons/si"
import { DiRuby} from "react-icons/di"
import { FaHtml5 } from "react-icons/fa";
import { DiCss3 } from "react-icons/di";
import { FaJsSquare } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { FaBootstrap } from "react-icons/fa";
import { FaSass } from "react-icons/fa";
import { FaDatabase } from "react-icons/fa";
import { IoLogoNodejs } from "react-icons/io";
import { SiDjango } from "react-icons/si";
import { SiNextdotjs } from "react-icons/si";
import { FaVuejs } from "react-icons/fa";
import { SiPostgresql } from "react-icons/si";
import { SiMysql } from "react-icons/si";
import { SiMongodb } from "react-icons/si";

function About() {
  return (
    <div className="container">
      <div className="">
        <img
          className="about__img"
          src={iconpeace}
          alt="emoju with peace signal"
        />
      </div>
      <div className="">
        <h2 className="container-title"> Know me better!</h2>
        <p className="container--text">
          Hi 👋🏽, my name is Steffany Naranjo Vargas, I am a Full stack software
          developer, with expirence working as a front end developer, UI
          developer and Full Stack Developer, I am a person who is passionate
          about technology and loves to learn new things every day, I am
          characterized for being responsable, proactive and creative. I adapt
          easily to new challenges and seek solutions to problems that arise
          sporadically. <br></br> <br></br> I started in this amazing world a
          few years ago with <b>HTML</b> and <b>CSS</b> but to become a full
          stack developer I studied at Holberton School, there I wrote my first
          code line with
          <code> printf("Hello, world!");</code> in <b>C programming</b>, so I
          started with a low level, after that I learned <b>Python</b> and
          started to develop more complex applications. But in the Tech world we
          never stop learning, that's why I started taking more courses,
          bootcamps and more and I learned <b>Javascript</b> and <b>Ruby on Rails</b>, 
          right i love to work with this two programmin lenguages, I think is an Excellent
          combination to work and we can do everuthing with <b>JS and RoR</b> 🚀 <br></br>
          <br></br>
          Well, I think that not everything in life is work so also in my free
          time I love to travel, to knew new places and new people, I really
          enjoy to spens time with my family and my friends so I always try to
          organize my time to be with them, I like to watch movies and series, I
          love superheroes 😎 and I am a huge fan of harry potter.🔮
        </p>
        <h2 className="container-title__2"> Skills</h2>
        <p className="container--text">
          Here you will find some of my skills and the technologies that I have
          expirence working on. Also i have the ability to quickly learn new
          languages and libraries. Excellent communication and creative skills.
          Optimal team and independent performance. Advance level of english,
          and native Spanish. Experience with agile framework like scrum and
          Kanban.
        </p>
        <div className="container--skills__sec">
          <div className="front-end">
            <h3>Front-end 💫</h3>
            <p className="container--fontend">
              I have experience in Front end with technologies such as: HTML,
              CSS, Bootstrap, Sass, Javascript, React and Next{" "}
            </p>
            <FaHtml5 />
            <DiCss3 />
            <FaBootstrap />
            <FaSass />
            <FaJsSquare />
            <FaReact />
            <SiNextdotjs />
            <FaVuejs />
          </div>
          <div className="front-end">
            <h3>Back end 🤖</h3>
            <p className="container--fontend">
              I have experience in back end with technologies such as: 
              Ruby on Rails, Python,Django and Node
            </p>
            <DiRuby />
            <SiRubyonrails />
            <FaPython />
            <SiDjango />
            <IoLogoNodejs />
          </div>
          <div className="front-end">
            <h3>Data base 👾</h3>
            <p className="container--fontend">
              I have experience in data base with technologies such as:
              PostgreSQL, MySQL and Mongo
            </p>
            <SiPostgresql />
            <SiMysql />
            <FaDatabase />
            <SiMongodb />
          </div>
        </div>
      </div>
    </div>
  );
}

export { About };
