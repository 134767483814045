import React, { useEffect, useState } from "react";
import "./styles/Nav.css";
import { Link, useLocation } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";

function Nav() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);
  return (
    <div>
      <header>
        <nav className="nav">
          <div className="nav-left">
            <ul>
              <li>
                <Link to="/">Steffany Naranjo V.</Link>
              </li>
            </ul>
          </div>
          <div className="nav-right">
            <ul className={click ? "nav-options active" : "nav-options"}>
              <li className="nav-right__li" onClick={closeMobileMenu}>
                <Link to="about">About me</Link>
              </li>
              <li className="nav-right__li" onClick={closeMobileMenu}>
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li className="nav-right__li" onClick={closeMobileMenu}>
                <Link to="blog">Blog</Link>
              </li>
              <li className="nav-right__li" onClick={closeMobileMenu}>
                <Link to="contact">Contact me</Link>
              </li>
            </ul>
            <div className="mobile-menu" onClick={handleClick}>
              {click ? <MdOutlineClose /> : <FiMenu />}
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export { Nav };
