import React from "react";
import "./styles/skills.css";
// import math from "./images/math-birthday.png";
import warning from "./images/warning-tape.png";

// import todo from "./images/todo.png";
// import calculator from "./images/calculator.png";
// import codeskills from "./images/codeskills.png";
// import bomberboot from "./images/bomberboot.png";
// import rick from "./images/rick.png";
import { BsGithub } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5"

function Skills() {
  return (
    <div className="containe--skill">
      <div className="contruction-div">
      < IoWarningOutline className="icon-warning" />
      <h1 className="title">
        This page in under construction
      </h1>
      <img 
        src={warning}
        alt="warning-tape"
        className="warning-tape"
      />
      <p> So far you can check my work here</p>
      <a
            href="https://github.com/steffanynaranjov/"
            target="_blank"
            rel="noreferrer">
            <BsGithub />
          </a>
          </div>
      {/* <div className="blog-container-skill">
        <div className="blog--container_img-skills">
          <img
            className="img-responsive__skills"
            src={math}
            alt="math-birthday project"
          />
          <h3 className="title--skill">Math Birthday Project</h3>
          <p className="blog--container_text">
            This is a single page project made with <b>HTML</b>, <b>CSS</b>,{" "}
            <b>JS</b>, <b>Bootstrap</b> and <b>J Query</b>. "Math birthday" is a
            date that is 10^n days after the day they were born. This web app
            will show you your next math birthday. <br></br>
          </p>
          <a
            href="https://github.com/steffanynaranjov/mathBirthday"
            target="_blank"
            rel="noreferrer">
            <BsGithub />
          </a>
        </div>
        <div className="blog--container_img-skills">
          <img
            className="img-responsive__skills"
            src={todo}
            alt="To do app project"
          />
          <h3>To-Do Project</h3>
          <p className="blog--container_text">
            This is a web app project made with <b>React</b>, <b>React-hooks</b>
            , <b>Redux</b> and <b>Css</b>. To-do Project is a web page for
            mobile devices where you can list things to do, mark them as
            completed when they are ready, delete them, and search through the
            different tasks <br></br>
          </p>
          <a
            href="https://github.com/steffanynaranjov/Todo-app"
            target="_blank"
            rel="noreferrer">
            <BsGithub />
          </a>
        </div>
        <div className="blog--container_img-skills">
          <img
            className="img-responsive__skills"
            src={calculator}
            alt="Perimeter and area calculator"
          />
          <h3>Perimeter and area calculator</h3>
          <p className="blog--container_text">
            In this project you will find a perimeter and area calculator for
            differents geometric figures such as triangle, square and circle,
            also you will find information about each figure This project was
            made with
            <b> HTML5</b>, <b>CSS3</b> and <b>JS</b>. <br></br>
          </p>
          <a
            href="https://github.com/steffanynaranjov/Calculator-perimeter_area"
            target="_blank"
            rel="noreferrer">
            <BsGithub />
          </a>
        </div>
        <div className="blog--container_img-skills">
          <img
            className="img-responsive__skills"
            src={codeskills}
            alt="Lading page for code skills"
          />
          <h3 className="title--skill">Lading page Codeskills</h3>
          <p className="blog--container_text">
            This is a landing page was made to present the codeskills product,
            this landing page was build with
            <b> HTML</b> and <b>Tailwind Css</b>.<br></br>
          </p>
          <a
            href="https://github.com/steffanynaranjov/CodeSkills-landing"
            target="_blank"
            rel="noreferrer">
            <BsGithub />
          </a>
        </div>
        <div className="blog--container_img-skills">
          <img
            className="img-responsive__skills"
            src={bomberboot}
            alt="Online HTML, CSS and JS text editor"
          />
          <h3 className="title--skill">Online HTML, CSS and JS text editor</h3>
          <p className="blog--container_text">
            This is a web app is an online text editor for HTML, CSS, and JS,
            with automatic preview function. For this project I use
            <b> React</b>, <b>React-hooks</b> and <b>Tailwind Css</b>
            <br></br>
          </p>
          <a
            href="https://github.com/steffanynaranjov/bomberbot_project_frontend"
            target="_blank"
            rel="noreferrer">
            <BsGithub />
          </a>
        </div>
        <div className="blog--container_img-skills">
          <img
            className="img-responsive__skills"
            src={rick}
            alt="Rick and Morty Api"
          />
          <h3 className="title--skill">Rick and Morty</h3>
          <p className="blog--container_text">
            This is a Vanilla app that use the rick and Morty API, to show the
            characters of the serie with relevant information about each od
            them, this app was made with
            <b> Javascript Vanilla</b>. <br></br>
          </p>
          <a
            href="https://github.com/steffanynaranjov/vanilla-app"
            target="_blank"
            rel="noreferrer">
            <BsGithub />
          </a>
        </div>
      </div> */}
    </div>
  );
}

export { Skills };
