import React from "react";
import { Nav } from "./components/Header/Nav";
import { Footer } from "./components/Footer/Footer";

const Layout = ({ children }) => {
  return (
    <div>
      <Nav />
      {children}
      <Footer />
    </div>
  );
};

export { Layout };
