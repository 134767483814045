import React from "react";
import "./styles/footer.css";
import { BsGithub } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

function Footer() {
  return (
    <div className="footer">
      <ul>
        <li>
          <a
            href="https://github.com/steffanynaranjov"
            target="_blank"
            rel="noreferrer">
            <BsGithub />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/steffanynaranjov "
            target="_blank"
            rel="noreferrer">
            <BsLinkedin />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/NaranjoSteffany"
            target="_blank"
            rel="noreferrer">
            <BsTwitter />
          </a>
        </li>
        <li>
          <a
            href="mailto:steffanynaranjov@gmail.com"
            target="_blank"
            rel="noreferrer">
            <MdEmail />
          </a>
        </li>
      </ul>
    </div>
  );
}

export { Footer };
