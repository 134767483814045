import React, { useRef } from "react";
import "./styles/contact.css";
import iconOkay from "./icon/icon-okay.png";
import emailjs from "emailjs-com";

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xurj0uj",
        "template_lhevqin",
        form.current,
        "user_zlA1m9bzqbBgfqHsOa0xb"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div>
      <img className="icon-contact" src={iconOkay} alt="emoji-okay" />
      <h1 className="title-form">Let's keep in touch</h1>
      <p className="text-form">
        Let me a message I will reach out to you as soon as possible.
      </p>
      <form ref={form} onSubmit={sendEmail} className="form">
        <label className="form--label" htmlFor="name">
          <span className="form-span">Whats your name?</span>
          <input
            className="form-input"
            placeholder="Name"
            type="text"
            name="from_name"
            required></input>
        </label>
        <label htmlFor="last-name">
          <span className="form-span">Whats your last name?</span>
          <input
            className="form-input"
            placeholder="Last name"
            type="text"
            name="last-name"
            required></input>
        </label>
        <label htmlFor="email">
          <span className="form-span">Whats your email?</span>
          <input
            className="form-input"
            placeholder="email"
            type="email"
            name="email"
            required></input>
        </label>
        <label htmlFor="message">
          <span className="form-span">Let me a message here!</span>
          <textarea
            className="form-input form--textarea"
            required
            placeholder="Send me a message here"
            name="message"
          />
        </label>
        <button className="button" type="submit" value="Send">
          Submit
        </button>
      </form>
    </div>
  );
};
