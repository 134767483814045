import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { About } from "../pages/About/About";
import { Layout } from "../Layout";
import { App } from "../App";
import { Skills } from "../pages/Skills/Skills";
import { Blog } from "../pages/Blog/Blog";
import { Contact } from "../pages/Contact/Contact";

const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/about" component={About} />
          <Route exact path="/portfolio" component={Skills} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export { Router };
