import React from "react";
import "./App.css";
import { Hero } from "./components/Hero/Hero";

function App() {
  return (
    <React.Fragment>
      <Hero />
    </React.Fragment>
  );
}

export { App };
