import React from "react";
import "./styles/buttonCv.css";
import CV from "./CV/SteffanyNaranjoV.pdf";

function ButtonCv() {
  return (
    <div className="container-button">
      <button className="btn btn-cv">
        <a href={CV} rel="noreferrer" download>
          Download CV
        </a>
      </button>
    </div>
  );
}

export { ButtonCv };
