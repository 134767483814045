import React from "react";
import "./styles/blog.css";
import imageBlog from "./images/blog1.png";
import libraries from "./images/libraries.png";
import store from "./images/store.png";
import recursion from "./images/recursion.png";
import preprocesor from "./images/preprocesor.png";
import iot from "./images/iot.png";

function Blog() {
  return (
    <div>
      <h1 className="title-blog">Blog post</h1>
      <div className="blog-container">
        <div className="blog--container__img">
          <a
            href="https://steffanynaranjo.medium.com/web-infrastructure-design-34cf451ee801"
            target="_blank"
            rel="noreferrer">
            <img className="img-responsive" src={imageBlog} alt="blog-post" />
          </a>
          <a
            href="https://steffanynaranjo.medium.com/differences-between-static-and-dynamic-libraries-f67c35220d3"
            target="_blank"
            rel="noreferrer">
            <img className="img-responsive" src={libraries} alt="blog-post" />
          </a>
          <a
            href="https://steffanynaranjo.medium.com/how-integers-are-stored-in-memory-using-twos-complement-8b4237f7426d"
            target="_blank"
            rel="noreferrer">
            <img className="img-responsive" src={store} alt="blog-post" />
          </a>
          <a
            href="https://steffanynaranjo.medium.com/worst-abuse-of-the-c-preprocessor-ioccc-winner-1986-ab4a5e7f6b8f"
            target="_blank"
            rel="noreferrer">
            <img className="img-responsive" src={preprocesor} alt="blog-post" />
          </a>
          <a
            href="https://steffanynaranjo.medium.com/worst-abuse-of-the-c-preprocessor-ioccc-winner-1986-ab4a5e7f6b8f"
            target="_blank"
            rel="noreferrer">
            <img className="img-responsive" src={recursion} alt="blog-post" />
          </a>
          <a
            href="https://steffanynaranjo.medium.com/worst-abuse-of-the-c-preprocessor-ioccc-winner-1986-ab4a5e7f6b8f"
            target="_blank"
            rel="noreferrer">
            <img className="img-responsive" src={iot} alt="blog-post" />
          </a>
        </div>
      </div>
      <button className="btn-read">
        <a
          href="https://steffanynaranjo.medium.com/"
          target="_blank"
          rel="noreferrer">
          Read More
        </a>
      </button>
    </div>
  );
}

export { Blog };
