import React from "react";
import logoComputer from "./img/logo-computer.png";
import "./styles/hero.css";
import { ButtonCv } from "../button-cv/ButtonCv";

function Hero() {
  return (
    <div className="hero">
      <h1 className="hero-title">Hi, welcome to my site!</h1>
      <img className="hero__img" src={logoComputer} alt="emoji with pc" />
      <p className="hero__text">
        I am a full stack software developer and I firmly believe that at this
        time what we can dream we can program it ✌️🚀
      </p>
      <ButtonCv />
    </div>
  );
}

export { Hero };
